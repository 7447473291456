import { Link, RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ListItem, Ol, Ul } from '../components/List';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { Typography } from '../components/Typography';
import { Paths } from '../paths';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const Text = styled(Typography)`
  font-size: 12pt;
  line-height: 115%;
  margin-bottom: 1rem;
`;

const Paragraph = styled(Typography)`
  font-size: 12pt;
  line-height: 1.75;
  margin-bottom: 1rem;
`;

const PrivacyPolicy: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Privacy Policy" description="Privacy Policy for Hypercite software and image" />
      <Container>
        <Text className="font-bold text-center" underline>
          HyperCite<sup>&reg;</sup> PRIVACY POLICY
        </Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> knows that you value your privacy. Your privacy is important to HyperCite
          <sup>&reg;</sup>, too. This Privacy Policy covers the information appearing on the website on which it is
          posted, which is operated by HyperCite<sup>&reg;</sup>. This Privacy Policy is designed to tell you about our
          practices regarding collection, use, and disclosure of information that you may provide via the Services.
          Please take a moment to read HyperCite<sup>&reg;</sup>’ privacy practices and contact HyperCite
          <sup>&reg;</sup> with any questions. By accessing or using our Services you acknowledge that you have read
          this Privacy Policy, and agree to be bound by the terms and conditions described herein, as well as our{' '}
          <Link to={Paths.TermsOfUse}>Terms of Use</Link>. This privacy policy and our Terms of Use constitute an
          agreement (“Agreement”) between you and HyperCite
          <sup>&reg;</sup> Inc., its subsidiaries, affiliates, and third-party associates (“HyperCite<sup>&reg;</sup>”)
        </Paragraph>

        <Paragraph>
          Each time you use the Services, the current version of this policy will apply. Policy changes will be
          reflected on this page. Since users are required to accept all changes to this policy in the Agreement,
          HyperCite<sup>&reg;</sup> encourages you to regularly check the date of this policy and review any changes
          made since the last time you used the Services.
        </Paragraph>

        <Text bold>Effective date: August 1, 2019</Text>

        <Text bold>What Kinds of Information Do We Collect?</Text>

        <Paragraph>
          In order to provide our services, we collect information. Some information you provide directly to us, some we
          collect automatically through our Services, and some may be collected from third parties. Some of the
          information we collect will be personally identifiable information, which is information that identifies you,
          or can be combined with other data, to identify you as a specific individual
        </Paragraph>

        <Text bold>We collect information you provide to us</Text>

        <Paragraph>
          For example, we collect information when you purchase a product or service, create an account, fill out a
          form, participate in a contest or promotion, request customer service, or otherwise communicate with us.
          <br />
          The types of personal information you may provide include:
        </Paragraph>

        <Ul>
          <ListItem>Contact information (such as name, email address, mailing address, and phone number);</ListItem>
          <ListItem>Payment information (including billing address, and bank account information); and</ListItem>
          <ListItem>Account log-in credentials (username, password, etc).</ListItem>
        </Ul>

        <Paragraph>
          We may also collect other information from or about you, such as information about what products or services
          you purchased, your interests, and demographic information. You may also provide us with additional
          information.
        </Paragraph>

        <Text bold>
          We also automatically collect information about your interactions with, and use of the Services as well as
          devices on which the Services used and accessed.
        </Text>

        <Text underline>Device Information</Text>

        <Paragraph>
          When you visit the Services, we may collect certain information from you, including your Internet Protocol
          (IP) address, the date and time of your visit, browser type, operating system, referral URL, the specific web
          pages visited during your connection, and the domain name from which you accessed the Services. In addition,
          we may collect information about your browsing behavior, such as the date and time you visit the Services, the
          areas or pages of the Services that you visit, the amount of time you spend viewing the Services, the number
          of times you return to the Services and other clickstream data.
        </Paragraph>

        <Text underline>Information from the use of the service</Text>

        <Paragraph>
          We may track how, and how often you use our Services, including which menus you use, pages you view, or search
          results you click on. You may interact with our support team during the use of our Services, in which case, we
          would collect information about your communications.
        </Paragraph>

        <Text underline>Cookies</Text>

        <Paragraph>
          Like many commercial websites, we may analyze how visitors use our Services through what is known as “cookie”
          technology. A cookie is a small text file that is placed on your computer when you access the Services and
          allows us to recognize you each time you visit the Services. We may use cookies to: (1) enhance or personalize
          your Services usage; (2) monitor Services usage; (3) manage the Services; and (4) improve the Services. If you
          choose, you can set your browser to reject cookies or you can manually delete individual or all of the cookies
          on your computer by following your browser’s help file directions. However, if your browser is set to reject
          cookies or you manually delete cookies, you may have some trouble accessing and using some of the pages and
          features that are currently on our Services, or that we may put on our Services in the future.
        </Paragraph>

        <Text underline>Web Beacons</Text>

        <Paragraph>
          We may also use web beacons on the Services, in our emails, and in our advertisements on other websites. Web
          beacons are tiny graphic images that are used to collect information about your Services visit, such as the
          pages you view and the features you use, as well as information about whether you open and/or act upon one of
          our emails or advertisements. We may also collect the URL or the website you visited immediately before coming
          to Services. Web beacons help us analyze our Services’ visitors’ behavior and measure the effectiveness of the
          Services and our advertising. We may work with service providers that help us track, collect, and analyze this
          and other site usage information.
        </Paragraph>

        <Paragraph>
          We may combine any information we collect, including through cookies and web beacons, with other information
          we have collected from you.
        </Paragraph>

        <Text bold>How do we use the information we collect?</Text>

        <Paragraph>
          Generally, we use and disclose the information we collect to provide the Services, to communicate with you, to
          advertise or promote our Services, to facilitate changes to or transfers of our business, as required by law,
          or with your consent.
        </Paragraph>

        <Paragraph>
          HyperCite<sup>&reg;</sup> has the right to use your personal information in such cases described below:
        </Paragraph>

        <Ol>
          <ListItem>To improve the Services or build new services;</ListItem>
          <ListItem>To personalize your experience;</ListItem>
          <ListItem>To fulfill your requests;</ListItem>
          <ListItem>To display relevant advertising;</ListItem>
          <ListItem>To provide customer service;</ListItem>
          <ListItem>To create backups and allow for disaster recovery;</ListItem>
          <ListItem>To comply with legal obligations;</ListItem>
          <ListItem>To troubleshoot our Services or enforce our terms of use and privacy policy</ListItem>
          <ListItem>To send you account notifications and updates about your Services;</ListItem>
          <ListItem>To encourage feedback;</ListItem>
          <ListItem>To maintain User Accounts;</ListItem>
          <ListItem>
            To detect and protect against error, fraud, malicious activity, or other suspicious or criminal activity;
          </ListItem>
          <ListItem>To authenticate your identity and access to the Services;</ListItem>
          <ListItem>To create an export of your Personal Information based on your authorization;</ListItem>
          <ListItem>
            To de-indentify your data to create aggregate information which we may use and disclose for any purpose.
          </ListItem>
        </Ol>

        <Text bold>Who Do We Share Personal Data With?</Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> has the right to disclose, share, or transfer your personal information to others in
          such cases described below:
        </Paragraph>

        <Ol>
          <ListItem>
            We may share your information with third parties, but only to the extent necessary to provide you with the
            Services.
          </ListItem>
          <ListItem>
            We may share your information with employees or contractors of HyperCite<sup>&reg;</sup>, but only to assist
            them in fulfilling their functions as employees or contractors.
          </ListItem>
          <ListItem>
            When you give your consent to do so: for example, when we tell you that the information you provide will be
            shared in some way and you provide us that information.
          </ListItem>
          <ListItem>
            When we are authorized or legally required to do so or that doing so is necessary or appropriate to comply
            with the law or legal processes or to respond to lawful requests or legal authorities, including but not
            limited to subpoenas, warrants, or court orders.
          </ListItem>
          <ListItem>
            In connection with any merger, transfer or sale of company assets, financing, acquisition, or similar
            transaction or circumstance, your information to any successors-in-interest in the event that HyperCite
            <sup>&reg;</sup> is acquired by, sold to, merged with, or transferred to a third-party Some or all of your
            personal information provided to HyperCite<sup>&reg;</sup> could be amongst the assets transferred.
          </ListItem>
          <ListItem>
            To enforce or apply our Privacy Policy, our <Link to={Paths.TermsOfUse}>Terms of Use</Link> or our other
            policies or agreements.
          </ListItem>
        </Ol>

        <Text bold>Limitations on data retention and account termination</Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> may keep your data as long as is permitted or required under the law. Additionally,
          data may be retained, backed up, and used in our system to satisfy any of the authorized uses under this
          Privacy Policy. For example, HyperCite<sup>&reg;</sup> may use retained data to prevent, investigate, or
          identify possible wrongdoing in connection with the Services or to comply with legal obligations.
        </Paragraph>

        <Text bold>Email and SMS Information</Text>

        <Paragraph>
          If you choose to correspond with us through our Website or via email we may retain the content of your
          messages together with your email address, other included information, and our responses.
        </Paragraph>

        <Text bold>The security of your information</Text>

        <Paragraph>
          HyperCite<sup>&reg;</sup> takes reasonable measures designed to protect the information that is collected from
          or about you from accidental or unlawful destruction, accidental loss or unauthorized access, use,
          modification, interference, or disclosure. Please be aware, however, that no method of transmitting
          information over the internet or storing information is completely secure. Accordingly, HyperCite
          <sup>&reg;</sup> cannot guarantee the absolute security of any information.
        </Paragraph>

        <Text bold>Children and Our Services</Text>

        <Paragraph>
          Our services are not directed to children, and you may not use our services if you are under the age of 18.
          You must also be old enough to consent to the processing of your personal data in your country.
        </Paragraph>

        <Text bold>Your Data Collection Choices</Text>

        <Paragraph>
          <u>Tracking Technologies</u>. You can set your browser to refuse all or some browser cookies, or to alert you
          when cookies are being sent. If you disable or refuse cookies, please note that some parts of the Services may
          then be inaccessible or not function properly.
        </Paragraph>

        <Text bold>How we respond to &quot;Do Not Track&quot; Signals</Text>

        <Paragraph>
          Your online browser settings may allow you to automatically transmit a “Do Not Track” signal to websites and
          online services you visit. Our Services do not respond to a “Do Not Track” signal from a visitor’s browser. If
          you would like to find out more about “Do Not Track,” please visit{' '}
          <a href="http://www.allaboutdnt.com/">www.allaboutdnt.com</a>.
        </Paragraph>

        <Text bold>Questions</Text>

        <Text>If you have any questions about this Privacy Policy, please contact us at:</Text>

        <Paragraph link underline>
          <a href="mailto:info@hypercite.net">info@hypercite.net</a>
        </Paragraph>
      </Container>
    </PublicRoute>
  );
};

export default PrivacyPolicy;
